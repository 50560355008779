import {
    cilDescription,
    cilLockLocked,
    cilUser
} from '@coreui/icons'

export const icons = {
    cilDescription,
    cilLockLocked,
    cilUser
}
