import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';

import Navigation from '../Navigation';
import LandingPage from '../Landing';
import SignInPage from '../SignIn';
import HomePage from '../Home';
import ReportsPage from '../Reports';
//import AccountPage from '../Account';
//import AdminPage from '../Admin';

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';

import '../../scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const App = () => (
  <Router>
    <React.Suspense fallback={loading}>
      <Switch>
        {/*
          <Route exact path={ROUTES.LANDING} component={LandingPage} />
          <Route path={ROUTES.HOME} component={HomePage} />
        */}

        <Route path={ROUTES.SIGN_IN} component={SignInPage} />
        <Route path={ROUTES.REPORTS} component={ReportsPage} />

        <Redirect to={ROUTES.DEFAULT} />
      </Switch>
    </React.Suspense>

      {/*
      <Route exact path={ROUTES.LANDING} component={LandingPage} />
      <Route path={ROUTES.ACCOUNT} component={AccountPage} />
      <Route path={ROUTES.ADMIN} component={AdminPage} />
      */}
  </Router>
);

export default withAuthentication(App);