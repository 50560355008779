import React from 'react';

import { withAuthorization } from '../Session';

import {
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CForm,
  CFormGroup,
  CSelect,
  CLabel,
  CRow,
  CSpinner,
  CDataTable,
  CCardFooter,
  CButton
} from '@coreui/react';

import Chrome from '../Chrome';

const fields = ['name', 'registered', 'role', 'status']

const usersData = [
  { id: 0, name: 'John Doe', registered: '2018/01/01', role: 'Guest', status: 'Pending' },
  { id: 1, name: 'Samppa Nori', registered: '2018/01/01', role: 'Member', status: 'Active' },
  { id: 2, name: 'Estavan Lykos', registered: '2018/02/01', role: 'Staff', status: 'Banned' },
  { id: 3, name: 'Chetan Mohamed', registered: '2018/02/01', role: 'Admin', status: 'Inactive' },
  { id: 4, name: 'Derick Maximinus', registered: '2018/03/01', role: 'Member', status: 'Pending' },
  { id: 5, name: 'Friderik Dávid', registered: '2018/01/21', role: 'Staff', status: 'Active' },
  { id: 6, name: 'Yiorgos Avraamu', registered: '2018/01/01', role: 'Member', status: 'Active' },
  { id: 7, name: 'Avram Tarasios', registered: '2018/02/01', role: 'Staff', status: 'Banned' },
  { id: 8, name: 'Quintin Ed', registered: '2018/02/01', role: 'Admin', status: 'Inactive' },
  { id: 9, name: 'Enéas Kwadwo', registered: '2018/03/01', role: 'Member', status: 'Pending' },
  { id: 10, name: 'Agapetus Tadeáš', registered: '2018/01/21', role: 'Staff', status: 'Active' },
  { id: 11, name: 'Carwyn Fachtna', registered: '2018/01/01', role: 'Member', status: 'Active' },
  { id: 12, name: 'Nehemiah Tatius', registered: '2018/02/01', role: 'Staff', status: 'Banned' },
  { id: 13, name: 'Ebbe Gemariah', registered: '2018/02/01', role: 'Admin', status: 'Inactive' },
  { id: 14, name: 'Eustorgios Amulius', registered: '2018/03/01', role: 'Member', status: 'Pending' },
  { id: 15, name: 'Leopold Gáspár', registered: '2018/01/21', role: 'Staff', status: 'Active' },
  { id: 16, name: 'Pompeius René', registered: '2018/01/01', role: 'Member', status: 'Active' },
  { id: 17, name: 'Paĉjo Jadon', registered: '2018/02/01', role: 'Staff', status: 'Banned' },
  { id: 18, name: 'Micheal Mercurius', registered: '2018/02/01', role: 'Admin', status: 'Inactive' },
  { id: 19, name: 'Ganesha Dubhghall', registered: '2018/03/01', role: 'Member', status: 'Pending' },
  { id: 20, name: 'Hiroto Šimun', registered: '2018/01/21', role: 'Staff', status: 'Active' },
  { id: 21, name: 'Vishnu Serghei', registered: '2018/01/01', role: 'Member', status: 'Active' },
  { id: 22, name: 'Zbyněk Phoibos', registered: '2018/02/01', role: 'Staff', status: 'Banned' },
  { id: 23, name: 'Aulus Agmundr', registered: '2018/01/01', role: 'Member', status: 'Pending' },
  { id: 42, name: 'Ford Prefect', registered: '2001/05/25', role: 'Alien', status: 'Don\'t panic!' }
]

const SubmitButton = (props) => {
  let text = "Generate Report";
  if (props.isLoading) {
    text = "Loading...";
  }

  return (
    <CButton type="submit" className="btn-sm btn-primary" disabled={props.isLoading}>
      {
        props.isLoading ?
        <CSpinner size="sm" style={{marginRight: '10px'}}/> : ""
      }
      {text}
    </CButton>
  )
};

class ReportControls extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reportTypes: [],
      reportTypesLoading: false,
      events: [],
      eventsLoading: false
    }
  }

  componentDidMount() {
    this.setState({eventsLoading: true, reportTypesLoading: true});

    this.props.firebase.firestore.collection('events')
    .orderBy('title')
    .get()
    .then((result) => {
      const events = [];
      result.forEach((event) => {
        events.push({
          id: event.id,
          title: event.get('title')
        });
      });

      this.setState({ events: events, eventsLoading: false });
    });

    setTimeout(() => {
      this.setState({
        reportTypesLoading: false,
        reportTypes: [{
          id: 'eventTicketsDaily',
          title: 'Sales by Ticket Price by Day',
        },{
          id: 'eventSpend',
          title: 'Total Activity by Show',
        },{
          id: 'eventRewardItems',
          title: 'Reward Items by Show',
        }]
      });
    }, 100);
  }

  render() {
    const {reportTypes, events} = this.state;

    const loading = this.state.reportTypesLoading || this.state.eventsLoading;

    return (
      <CCol sm={6} lg={3}>
        <CCard>
          <CCardHeader>
            <strong>Report Settings</strong>
          </CCardHeader>

          <CCardBody>
            <CForm onSubmit={this.props.onSubmit}>
              <CFormGroup>
                <CLabel className="col-form-label">Report Type</CLabel>
                <CSelect
                  defaultValue=""
                  name="reportType"
                  onChange={this.props.onChange}
                >
                  <option
                    key="default"
                    value=""
                    disabled
                  >
                    {loading ? 'Loading report types...' : 'Select report type'}
                  </option>
                  {reportTypes.map((item) => (
                    <option
                      key={item.id}
                      value={item.id}
                    >
                      {item.title}
                    </option>
                  ))}
                </CSelect>
              </CFormGroup>

              <CFormGroup>
                <CLabel className="col-form-label">Event</CLabel>
                <CSelect
                  defaultValue=""
                  name="eventId"
                  onChange={this.props.onChange}
                >
                  <option key="default" value="" disabled>Select Event</option>
                  {events.map((item) => (
                    <option key={item.id} value={item.id}>{item.title}</option>
                  ))}
                </CSelect>
              </CFormGroup>

              <CFormGroup>
                <CLabel className="col-form-label">Exclude MAX Users?</CLabel>
                <CSelect
                  defaultValue="1"
                  name="excludeMaxUsers"
                  onChange={this.props.onChange}
                >
                  <option key="1" value="1">Yes, exclude MAX users from report</option>
                  <option key="0" value="0">No, include MAX users in report</option>
                </CSelect>
              </CFormGroup>

              <CFormGroup className="form-actions mb-0 pt-2">
                <SubmitButton isLoading={loading} />
              </CFormGroup>

            </CForm>
          </CCardBody>

          <CCardFooter>
            <p className="note" style={{margin: "10px 0"}}><small>All times are <a target="_blank" href="https://www.google.com/search?q=%22America/Chicago%22+timezone">Central</a>.</small></p>
          </CCardFooter>

        </CCard>
      </CCol>
    );
  }
}

class ReportOutput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  downloadCsv = () => {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(this.props.report.csv));
    
    // TODO: Set this name better
    element.setAttribute('download', 'report.csv');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  render() {
    const {headers, rows} = this.state;
    const table = this.props.report.raw || {};
    const csv = this.props.report.csv;
    const loading = this.props.loading;

    const noDataDisplay = loading ? 'Loading report...' : 'No report data.';

    return (
      <CCol sm={6} lg={9}>
        <CCard>
  
          <CCardHeader>
            <strong>Report Output</strong>
            {csv ?
            <div className="card-header-actions">
              <CButton className="btn-sm btn-success" onClick={this.downloadCsv}>Download CSV</CButton>
            </div> : ""
            }
          </CCardHeader>

          <CCardBody style={{ style: "overflow-x: scroll" }}>
            {table.headers ? <CDataTable fields={table.headers} items={table.rows}/> : (
              loading ?
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-info" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
              :
              <p>No report data.</p>
            )}
          </CCardBody>

        </CCard>
      </CCol>
    )
  }
}

class ReportsPageBase extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      eventId: null,
      reportType: null,
      excludeMaxUsers: "1",
      report: {
        raw: null,
        csv: null
      }
    };
  }

  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      report: {
        raw: null,
        csv: null
      }
    });
  }

  onSubmit = (event) => {
    console.log(this.state);
    
    event.preventDefault();

    this.getReport();

    return false;
  }

  async getReport(reportType, eventId, excludeMaxUsers=true) {
    const runReport = this.props.firebase.endpoints.reports.runReport;
    
    if (this.state.reportType === null) {
      alert('Please select a report type');
      return;
    }

    if (this.state.eventId === null) {
      alert('Please select an event');
      return;
    }

    this.setState({loading: true});

    const results = await runReport({
      reportType: this.state.reportType,
      eventId: this.state.eventId,
      excludeMaxUsers: this.state.excludeMaxUsers === "1" ? true : false
    });

    // Convert null/undefined to empty string
    for (const idx in results.data.raw.rows) {
      const row = results.data.raw.rows[idx];
      for (const header of results.data.raw.headers) {
        if (row[header] === null || row[header] === undefined) {
          row[header] = "";
        }
      }
    }

    console.log(results);

    this.setState({
      loading: false,
      report: results.data
    });
  }

  render() {
    return (
      <CRow className="justify-content-center">
        <ReportControls {...this.props} onChange={this.onChange} onSubmit={this.onSubmit} />
        <ReportOutput {...this.props} report={this.state.report} loading={this.state.loading} />
      </CRow>
    );
  }
}

const ReportsPage = Chrome(ReportsPageBase);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(ReportsPage);