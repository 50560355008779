import React from 'react';
import { Link } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';

import {
  CBadge,
  CContainer,
  CCollapse,
  CDropdown,
  CDropdownItem,
  CDropdownToggle,
  CFade,
  CHeader,
  CHeaderBrand,
  CHeaderNav,
  CHeaderNavItem,
  CImg,
  CDropdownMenu,
  CNavbar,
  CNavbarBrand,
  CNavbarNav,
  CNavLink,
  CToggler
} from '@coreui/react';

import CIcon from '@coreui/icons-react';

import { AuthUserContext } from '../Session';


const Chrome = Component => props => (
  <AuthUserContext.Consumer>
    {authUser => (
      <div className="c-app c-default-layout">
        <div className="c-wrapper">
          <CHeader className="px-3">
            <CHeaderBrand className="mb-0 h5 px-3 mr-auto">
              Set.Live Reporting System
            </CHeaderBrand>
            <CHeaderNav> {/* className="px-3" */}
              <CDropdown inNav className="c-header-nav-items mx-2" direction="down">
                <CDropdownToggle className="c-header-nav-link" caret={false}>
                  <div className="c-avatar">
                    <CImg
                      src={props.authUser.photoURL}
                      className="c-avatar-img"
                      alt="admin@bootstrapmaster.com"
                    />
                  </div>
                </CDropdownToggle>
                <CDropdownMenu className="pt-0" placement="bottom-end">
                  <CDropdownItem
                    header
                    tag="div"
                    color="light"
                    className="text-center"
                  >
                    <strong>Actions</strong>
                  </CDropdownItem>
                  
                  <CDropdownItem onClick={() => props.history.push(ROUTES.REPORTS)}>
                    <CIcon name="cil-description" className="mfe-2" />
                    Reports
                  </CDropdownItem>

                  <CDropdownItem divider />
                  
                  <CDropdownItem onClick={props.firebase.doSignOut}>
                    <CIcon name="cil-lock-locked" className="mfe-2" />
                    Log Out
                  </CDropdownItem>
                </CDropdownMenu>
              </CDropdown>
            </CHeaderNav>
          </CHeader>

          <div className="c-body">
            <main className="c-main">
              <CContainer fluid>
                <CFade>
                  <Component {...props} authUser={authUser} />
                </CFade>
              </CContainer>
            </main>
          </div>

        </div>
      </div>
    )
    }
  </AuthUserContext.Consumer>
);

export default Chrome;