import React from 'react';
 
import { withAuthorization } from '../Session';

import Chrome from '../Chrome';

const HomePageBase = (props) => (
  <div>
    <h1>Home Page</h1>
    <p>Welcome {props.authUser.displayName}!</p>
  </div>
);

const HomePage = Chrome(HomePageBase);
 
const condition = authUser => !!authUser;
 
export default withAuthorization(condition)(HomePage);
