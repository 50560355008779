import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/firestore';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    
    this.auth = app.auth();
    this.functions = app.functions();
    this.firestore = app.firestore();

    this.endpoints = {
      auth: {
        maxLogin: this.functions.httpsCallable('auth-maxLogin')
      },
      reports: {
        runReport: this.functions.httpsCallable('reports-runReport')
      }
    };
  }

  doSignInWithEmailAndPassword = async (email, password) => {
    const auth = this.endpoints.auth.maxLogin;
    
    const response = await auth({email, password});
    
    const token = response.data.token;
    if (!token) {
      throw new Error('No token');
    }
    
    return await this.auth.signInWithCustomToken(token);
  }

  doSignOut = async () => {
    console.log("Logging out");

    const response = await this.auth.signOut();
    
    console.log(response);
    
    return response;
  }
}
 
export default Firebase;
