import React from 'react';

import { withAuthentication, withAuthorization } from '../Session';

import Chrome from '../Chrome';

const LandingPageBase = (props) => {
  console.log(props);
  return (
    <p>Landing Page!</p>
  )
};

const LandingPage = Chrome(LandingPageBase);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(LandingPage);