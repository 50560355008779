import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
  CSpinner
} from '@coreui/react'

import CIcon from '@coreui/icons-react'

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
  loading: false
};

const SignInError = ({ error }) => (
  //<CCol xs="6" className="text-right">
  //  <p>Error logging in</p>
  //</CCol>
  <CRow>
    <CCol xs="12">
      <CAlert color="danger">
        Error logging in.  Please double check your credentials.
      </CAlert>
    </CCol>
  </CRow>
);

const SubmitButton = (props) => {
  let text = "Login";
  if (props.loading) {
    text = "Loading";
  }
  
  const isDisabled = props.invalid || props.loading;

  return (
    <CButton type="submit" color="primary" className="px-4" disabled={isDisabled}>
      {
        props.loading ?
        <CSpinner size="sm" style={{marginRight: '10px'}}/> : ""
      }
      {text}
    </CButton>
  )
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, password } = this.state;

    this.setState({loading: true});

    console.log(`Logging in email ${email}`);

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.DEFAULT);
      })
      .catch(error => {
        this.setState({ error: error, loading: false});
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value, error: null});
  };

  render() {
    const { email, password, error, loading } = this.state;

    const invalid = password === '' || email === '';

    return (
      <CForm onSubmit={this.onSubmit}>
        <h1>Login</h1>
        <p className="text-muted">Sign In to your account</p>
        <CInputGroup className="mb-3">
          <CInputGroupPrepend>
            <CInputGroupText>
              <CIcon name="cil-user" />
            </CInputGroupText>
          </CInputGroupPrepend>
          <CInput
            type="text"
            placeholder="Email"
            autoComplete="email"
            name="email"
            value={email}
            onChange={this.onChange}
          />
        </CInputGroup>
        <CInputGroup className="mb-4">
          <CInputGroupPrepend>
            <CInputGroupText>
              <CIcon name="cil-lock-locked" />
            </CInputGroupText>
          </CInputGroupPrepend>
          <CInput
            type="password"
            placeholder="Password"
            autoComplete="current-password"
            name="password"
            value={password}
            onChange={this.onChange}
          />
        </CInputGroup>
        {error && <SignInError error={error} />}
        <CRow>
          <CCol sm="6">
            <SubmitButton invalid={invalid} loading={loading} />
          </CCol>
          {/*error && <SignInError error={error} />*/}
        </CRow>
      </CForm>
    );
  }
};

const SignInForm = withRouter(withFirebase(SignInFormBase));

const SignInPage = () => (
  <div className="c-app c-default-layout flex-row align-items-center">
    <CContainer>
      <CRow className="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard className="p-4">
              <CCardBody>
                <SignInForm />
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
);

export default SignInPage;

export { SignInForm };
